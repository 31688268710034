<template>
  <v-list dense fixed height="70vh" class="col">
    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :to="{ name: item.to }"
        class="side_nav_item"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase">{{
            $t(item.text)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SideNav',
  data: () => ({
    selectedItem: 1,
    items: [
      {
        text: 'profileTitle',
        icon: 'mdi-account-outline',
        to: 'Profile'
      },
      {
        text: 'subscriptions',
        icon: 'mdi-credit-card-clock-outline',
        to: 'Subscriptions'
      },
      { text: 'settingsTitle', icon: 'mdi-settings', to: 'Settings' },
      { text: 'willTitle', icon: 'mdi-book-account-outline', to: 'MyWill' }
    ]
  })
})
</script>
<style lang="scss">
.side_nav_item {
  font-family: sans-serif;
  margin: 20px auto;
  padding: 10px;
}
</style>
